import Navbar from "../components/navbar"
import Footer from "../components/footer"
import { Link } from "react-router-dom";
import '../style/privacy.css'
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react"


function Privacy() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :

                <div id='bodyColor'>
                    <Navbar />
                    <div className="row col featurette">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <h1 style={{ color: "rgb(0, 225, 0)", textAlign: 'left', marginTop: '100px' }}>Informativa sulla privacy</h1>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" style={{ marginTop: '100px' }}>Finalità del trattamento </h4>
                            <p className="lead privacy-text" >Il trattamento dei dati ha le finalità direttamente connesse e strumentali all’erogazione del servizio richiesto, inoltre potranno essere utilizzati per l’invio di materiale pubblicitario, attività di marketing e di informazioni relative a prodotti che MACO DJ espone ed eroga sul sito; rilevazione del grado di soddisfazione dell’utente sulla qualità dell’attività svolta, statistiche internet, feed back di gradimento, acquisizioni preliminari di informazioni dirette alla conclusione di un accordo o alla fornitura di un intrattenimento ecc.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Modalità del trattamento </h4>
                            <p className="lead privacy-text" >I dati verranno trattati principalmente con strumenti elettronici e informatici e memorizzati sia su supporti informatici che su supporti cartacei che su ogni altro tipo di supporto idoneo, nel rispetto delle misure minime di sicurezza ai sensi del Disciplinare Tecnico in materia di misure minime di sicurezza, Allegato B del Codice in materia di trattamento dei dati personali.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text">Natura facoltativa dell’acquisizione dei dati</h4>
                            <p className="lead privacy-text" >L’acquisizione dei dati personali ha natura facoltativa. Tuttavia il mancato conferimento, anche parziale, dei dati personali richiesti per la compilazione del Modulo di Registrazione per l’Accesso al Servizio può determinare l’impossibilità di procedere alla completa erogazione del Servizio stesso.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text">Soggetti a cui potranno essere comunicati</h4>
                            <p className="lead privacy-text" >I dati personali potranno essere comunicati al personale dell’attività che svolga le funzioni di responsabile e incaricato del trattamento; il personale è stato debitamente istruito in materia di sicurezza e protezione dei dati personali; Resta in ogni caso fermo il divieto di comunicare o diffondere i dati personali a soggetti non necessari per l’espletamento dei servizi.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Diritti dell’interessato</h4>
                            <p className="lead privacy-text" >In relazione al trattamento di dati personali, ai sensi dell’art. 13 del D.Lg. n.196 del 30.6.2003, l’ interessato ha il diritto di ottenere la conferma dell’esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Ha diritto di ottenere l’indicazione:</h4>
                            <p className="lead privacy-text" >
                                <ul>
                                    <li>dell’origine dei dati personali.</li>
                                    <li>delle finalità e modalità del trattamento.</li>
                                    <li>della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici.</li>
                                    <li>degli estremi identificativi del titolare e dei responsabili del trattamento.</li>
                                    <li>dei soggetti o delle categorie di soggetti ai quali i dati possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati.</li>
                                </ul>
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Ha diritto di ottenere:</h4>
                            <p className="lead privacy-text" >l’aggiornamento o l’integrazione dei dati, la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text">Hai il diritto di opporti, in tutto o in parte:</h4>
                            <p className="lead privacy-text" >Per motivi legittimi al trattamento dei dati personali che ti riguardano, ancorché pertinenti allo scopo della raccolta, al trattamento dei dati personali che ti riguardano ai fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Il titolare del trattamento dei dati è:</h4>
                            <p className="lead privacy-text" >Matteo Corvino | Via Raffaello Sanzio, 5 – 24036 Ponte S. Pietro (Bg) Italia | macodeejay@gmail.com
                            </p>
                            <h1 style={{ color: "rgb(0, 225, 0)", textAlign: 'left', marginTop: '100px' }}>Utilizzo dei Cookies</h1>
                            <p className="lead privacy-text" >Per rendere i propri servizi il più possibile efficienti e semplici da utilizzare questo Sito fa uso di cookies. Pertanto, quando si visita il Sito, viene inserita una quantità minima di informazioni nel dispositivo dell’Utente, come piccoli file di testo chiamati “cookie”, che vengono salvati nella directory del browser Web dell’Utente. Esistono diversi tipi di cookie, ma sostanzialmente lo scopo principale di un cookie è quello di far funzionare più efficacemente il Sito e di abilitarne determinate funzionalità.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >I cookie sono utilizzati per migliorare la navigazione globale dell’Utente. In particolare:</h4>
                            <p className="lead privacy-text" >Consentono di navigare in modo efficiente da una pagina all’altra del sito Web.
                                Memorizzano il nome utente e le preferenze inserite.
                                Consentono di evitare di inserire le stesse informazioni (come nome utente e password) più volte durante la visita.
                                Misurano l’utilizzo dei servizi da parte degli Utenti, per ottimizzare l’esperienza di navigazione e i servizi stessi.
                                Presentano informazioni pubblicitarie mirate in funzione degli interessi e del comportamento manifestato dall’Utente durante la navigazione.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Come posso evitare la memorizzazione di cookie sul mio computer?</h4>
                            <p className="lead privacy-text" >È necessario impostare le preferenze per i cookie su ogni browser Internet Explorer, Google Chrome, Mozilla Firefox o Safari. Esistono diversi modi per gestire i cookie, ad esempio: abilitare o disabilitare completamente i cookie; eliminare cookie specifici che sono già stati memorizzati all'interno del browser; bloccare la memorizzazione di cookie sul vostro computer da parte di siti Web specifici; oppure bloccare i cookie di terze parti quando, mentre navigate su un sito Web, i cookie sono memorizzati sul vostro computer da un altro sito Web.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text">Come posso rimuovere/gestire i cookie sul mio computer?</h4>
                            <p className="lead privacy-text" >Nel caso in cui Lei abbia dubbi o preoccupazioni in merito all’utilizzo dei cookie Le è sempre possibile intervenire per impedirne l’impostazione e la lettura, ad esempio modificando le impostazioni sulla privacy all’interno del Suo browser al fine di bloccarne determinati tipi o utilizzando il tool che Le mettiamo a disposizione in questa Informativa.
                                Per rimuovere i cookie sul proprio pc vi invitiamo a consultare questi siti web:
                                <br></br>
                                <Link to='https://www.youronlinechoices.com/'>Your Online Choices</Link>
                                <br></br>
                                <Link to='https://cookiepedia.co.uk/'>www.cookiepedia.co.uk</Link>
                            </p>

                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Per la gestione dei cookie sui principali browser, visitate i seguenti link:</h4>
                            <p className="lead privacy-text" >
                                <br></br>
                                <Link to='https://support.microsoft.com/it-it/windows'>Internet Explorer</Link>
                                <br></br>
                                <Link to='https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies'>Google Chrome</Link>
                                <br></br>
                                <Link to='https://support.mozilla.org/it/kb/Gestione%20dei%20cookie?redirectlocale=en-US&redirectslug=Cookies'>Firefox</Link>
                                <br></br>
                                <Link to='https://support.apple.com/?path=Safari/3.0/en/9277.html'>Safari</Link>
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text">Titolare del trattamento dei dati</h4>
                            <p className="lead privacy-text" >Dal momento che l’installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i servizi utilizzati all’interno di questo spazio online non può essere tecnicamente controllata dal Titolare, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti è da considerarsi indicativo. Per ottenere informazioni complete, consulta la privacy policy degli eventuali servizi terzi elencati in questo documento.
                            </p>
                            <br></br>
                            <p className="lead privacy-text" >Vista l’oggettiva complessità legata all’identificazione delle tecnologie basate sui Cookie ed alla loro integrazione molto stretta con il funzionamento del web, l’Utente è invitato a contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo all’utilizzo dei Cookie stessi e ad eventuali utilizzi degli stessi – ad esempio ad opera di terzi – effettuati tramite questo sito.
                            </p>
                            <br></br>
                            <p className="lead privacy-text" >Il presente documento si riferisce a tutti gli spazi online (siti, piattaforme, strumenti internet, etc) di proprietà del Titolare. Per ottenere ulteriori informazioni in merito agli strumenti relativi ad uno specifico spazio, l’Utente è pregato di contattare il Titolare ai riferimenti forniti nel presente documento.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Tipologie di cookie</h4>
                            <p className="lead privacy-text" >Esistono vari tipi di cookie. Qui di seguito sono riportati i tipi cookie che possono essere utilizzati nel Sito con una descrizione della finalità legata all’uso.
                                Attenzione! L’elenco potrebbe non essere completo a causa di modifiche ai servizi di terze parti. Fare riferimento alle relative Privacy Policy per maggiori dettagli.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text">Cookie tecnici</h4>
                            <p className="lead privacy-text" >I cookie di questo tipo sono necessari per il corretto funzionamento di alcune aree del sito. I cookie di questa categoria comprendono sia cookie persistenti che cookie di sessione. In assenza di tali cookie, il sito o alcune porzioni di esso potrebbero non funzionare correttamente. Pertanto, vengono sempre utilizzati, indipendentemente dalle preferenze dall’utente. I cookie di questa categoria vengono sempre inviati dal nostro dominio.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Cookie analitici</h4>
                            <p className="lead privacy-text" >I cookie di questo tipo vengono utilizzati per raccogliere informazioni sull’utilizzo del sito. Il Titolare usa tali informazioni per analisi statistiche, per migliorare il sito e semplificarne l’utilizzo, oltre che per monitorarne il corretto funzionamento. Questo tipo di cookie raccoglie informazioni in FORMA ANONIMA sull’attività degli utenti nel sito e sul modo in cui sono arrivati al Sito e alle pagine visitate. I cookie di questa categoria vengono inviati dal Sito stesso o da domini di terze parti.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Cookie di profilazione di prima parte</h4>
                            <p className="lead privacy-text" >Questi cookie sono utilizzati per tracciare un profilo relativo al cliente e personalizzare la visualizzazione del sito e comunicazioni collegate per renderle più adatte all’utente stesso.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Questo sito non utilizza cookie di profilazione di prima parte</h4>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Cookie per l’integrazione di funzionalità di terze parti</h4>
                            <p className="lead privacy-text" >I cookie di questo tipo vengono utilizzati per integrare funzionalità di terze parti nel Sito (ad esempio, moduli per i commenti o icone di social network che consentono ai visitatori di condividere il contenuto del sito). I cookie di questa categoria possono essere inviati dai domini dei siti partner o che comunque offrono le funzionalità presenti nel sito.
                                <br></br>
                                Il Titolare informa che non accettando l’installazione di questi cookie alcune funzionalità di questo sito potrebbero non funzionare correttamente. Il Titolare ricorda all’Utente che è possibile modificare le preferenze relative ai cookie in qualsiasi momento. È anche possibile disabilitare in qualsiasi momento i cookie dal browser, ma questa operazione potrebbe impedire all’Utente di utilizzare alcune parti del Sito.
                                <br></br>
                                Attenzione! Una volta installati i cookie di terze parti il Titolare non può in alcun modo cancellarli direttamente. È necessario accedere alla pagina telativa all’informativa sulla privacy di ogni sito di terze parti per rimuovere i relativi cookie. Queste informative sulla privacy possono essere diverse da quella adottata dal Titolare, che quindi non risponde per Siti di terze parti.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Google Analytics (Google Inc.) con IP anonimizzato</h4>
                            <p className="lead privacy-text" >È utilizzato per il conteggio delle visite sul nostro sito web e per statistiche sull’utilizzo dello stesso. Serve per migliorare il servizio offerto. In questo Google Analytics è stato impostato per anonimizzare gli IP, quindi potremo vedere solo informazioni aggregate e non specifiche del tuo caso.
                                <br></br>
                                <Link to='https://policies.google.com/privacy?hl=en'>Privacy Policy – Opt Out</Link>
                                <br></br>
                                Ai sensi dell’art. 122 secondo comma del D.lgs. 196/2003 il consenso all’utilizzo di tali cookie è espresso dall’interessato mediante il settaggio individuale che ha scelto liberamente per il browser utilizzato per la navigazione nel sito, ferma restando la facoltà dell’utente di comunicare in ogni momento al Titolare del trattamento la propria volontà in merito ad dati gestiti per il tramite dei cookie che il browser stesso abbia accettato.
                            </p>
                            <hr></hr>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Pulsante sociali di Facebook (Facebook, Inc.)</h4>
                            <p className="lead privacy-text" >
                                <Link to='https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0'>Privacy Policy – Cookie Policy</Link>
                            </p>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Remarketing con Google Analytics per la pubblicità display:</h4>
                            <p className="lead privacy-text" >
                                <Link to='https://policies.google.com/privacy?hl=it'>Privacy Policy</Link>
                            </p>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Youtube, video e link ai canali (Google Inc.):</h4>
                            <p className="lead privacy-text" >
                                <Link to='https://policies.google.com/technologies/cookies?hl=it&gl=it'>Cookie Policy</Link>
                            </p>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Facebook: Remarketing con Facebook e conversione delle campagne</h4>
                            <br></br>
                            <h4 className="featurette-heading fw-normal lh-1 privacy-title-text" >Viene utilizzato per fare statistiche sulle campagne che promuoviamo su Facebook.</h4>
                            <p className="lead privacy-text" >
                                <Link to='https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0'>Privacy Policy – Cookie Policy</Link>
                            </p>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <Footer />
                </div>
            }
        </div>
    )
}

export default Privacy;