import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/home';
import Siae from './pages/siae';
import Marketplace from './pages/marketplace';
import Privacy from './pages/privacy';
import Matrimonio from './pages/matrimonio';
import Aperitivi from './pages/aperitivi';
import Pranziecene from './pages/pranzi&cene';
import Festedilaurea from './pages/festedilaurea';
import Battesimi from './pages/battesimi';
import Compleanni from './pages/compleanni';
import EventiAziende from './pages/eventiaziende';
import Tutorial from './pages/tutorial';
import Chisiamo from './pages/chisiamo';


function App() {

  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/marketplace' element={<Marketplace />} />
            <Route path='/siae' element={<Siae />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/matrimonio' element={<Matrimonio />} />
            <Route path='/aperitivi' element={<Aperitivi />} />
            <Route path='/pranzi&cene' element={<Pranziecene />} />
            <Route path='/festedilaurea' element={<Festedilaurea />} />
            <Route path='/battesimi' element={<Battesimi />} />
            <Route path='/compleanni' element={<Compleanni />} />
            <Route path='/tutorial' element={<Tutorial />} />
            <Route path='/eventiaziendali' element={<EventiAziende />} />
            <Route path='/chisiamo' element={<Chisiamo/>} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
