
import Contact from '../components/contact/contact'
import 'aos/dist/aos.css'
import '../style/servizi.css'
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import AOS from 'aos';
import { useEffect } from "react"
import 'aos/dist/aos.css'
import '../style/marketplace.css'
import CubeMarketSection from '../components/cubeMarketSection';
import img1p1 from '../images/marketplace/prodotto1/1.jpg'
import img2p1 from '../images/marketplace/prodotto1/2.jpg'
import img3p1 from '../images/marketplace/prodotto1/3.jpg'
import img1p2 from '../images/marketplace/prodotto2/1.jpg'
import img2p2 from '../images/marketplace/prodotto2/2.jpg'
import img3p2 from '../images/marketplace/prodotto2/3.jpg'
import img1p3 from '../images/marketplace/prodotto3/1.jpg'
import img2p3 from '../images/marketplace/prodotto3/2.jpg'
import img3p3 from '../images/marketplace/prodotto3/3.jpg'
import img1p4 from '../images/marketplace/prodotto4/1.jpg'
import img2p4 from '../images/marketplace/prodotto4/2.jpg'
import img3p4 from '../images/marketplace/prodotto4/3.jpg'
import img1p5 from '../images/marketplace/prodotto5/1.jpg'
import img2p5 from '../images/marketplace/prodotto5/2.jpg'
import img3p5 from '../images/marketplace/prodotto5/3.jpg'
import img1p6 from '../images/marketplace/prodotto6/1.jpg'
import img2p6 from '../images/marketplace/prodotto6/2.jpg'
import img3p6 from '../images/marketplace/prodotto6/3.jpg'
import img1p7 from '../images/marketplace/prodotto7/1.jpg'
import img2p7 from '../images/marketplace/prodotto7/2.jpg'
import img3p7 from '../images/marketplace/prodotto7/3.jpg'
import img1p8 from '../images/marketplace/prodotto8/1.jpg'
import img2p8 from '../images/marketplace/prodotto8/2.jpg'
import img3p8 from '../images/marketplace/prodotto8/3.jpg'
import img1p9 from '../images/marketplace/prodotto9/1.jpg'
import img2p9 from '../images/marketplace/prodotto9/2.jpg'
import img3p9 from '../images/marketplace/prodotto9/3.jpg'
import img1p10 from '../images/marketplace/prodotto10/1.jpg'
import img2p10 from '../images/marketplace/prodotto10/2.jpg'
import img3p10 from '../images/marketplace/prodotto10/3.jpg'
import img1p11 from '../images/marketplace/prodotto11/1.jpg'
import img2p11 from '../images/marketplace/prodotto11/2.jpg'
import img3p11 from '../images/marketplace/prodotto11/3.jpg'
import img1p12 from '../images/marketplace/prodotto12/1.jpg'
import img2p12 from '../images/marketplace/prodotto12/2.jpg'
import img3p12 from '../images/marketplace/prodotto12/3.jpg'
import img1p13 from '../images/marketplace/prodotto13/1.jpg'
import img2p13 from '../images/marketplace/prodotto13/2.jpg'
import img3p13 from '../images/marketplace/prodotto13/3.jpg'
import img1p14 from '../images/marketplace/prodotto14/1.jpg'
import img2p14 from '../images/marketplace/prodotto14/2.jpg'
import img3p14 from '../images/marketplace/prodotto14/3.jpg'
import img1p15 from '../images/marketplace/prodotto15/1.jpg'
import img2p15 from '../images/marketplace/prodotto15/2.jpg'
import img3p15 from '../images/marketplace/prodotto15/3.jpg'
import img1p16 from '../images/marketplace/prodotto16/1.jpg'
import img2p16 from '../images/marketplace/prodotto16/2.jpg'
import img3p16 from '../images/marketplace/prodotto16/3.jpg'
import img1p17 from '../images/marketplace/prodotto17/1.jpg'
import img2p17 from '../images/marketplace/prodotto17/2.jpg'
import img3p17 from '../images/marketplace/prodotto17/3.jpg'
import img1p18 from '../images/marketplace/prodotto18/1.jpg'
import img2p18 from '../images/marketplace/prodotto18/2.jpg'
import img3p18 from '../images/marketplace/prodotto18/3.jpg'
import img1p19 from '../images/marketplace/prodotto19/1.jpg'
import img2p19 from '../images/marketplace/prodotto19/2.jpg'
import img3p19 from '../images/marketplace/prodotto19/3.jpg'
import img1p20 from '../images/marketplace/prodotto20/1.jpg'
import img2p20 from '../images/marketplace/prodotto20/2.jpg'
import img3p20 from '../images/marketplace/prodotto20/3.jpg'
import img1p21 from '../images/marketplace/prodotto21/1.jpg'
import img2p21 from '../images/marketplace/prodotto21/2.jpg'
import img3p21 from '../images/marketplace/prodotto21/3.jpg'
import img1p22 from '../images/marketplace/prodotto22/1.jpg'
import img2p22 from '../images/marketplace/prodotto22/2.jpg'
import img3p22 from '../images/marketplace/prodotto22/3.jpg'
import img1p23 from '../images/marketplace/prodotto23/1.jpg'
import img2p23 from '../images/marketplace/prodotto23/2.jpg'
import img3p23 from '../images/marketplace/prodotto23/3.jpg'
import img1p24 from '../images/marketplace/prodotto24/1.jpg'
import img2p24 from '../images/marketplace/prodotto24/2.jpg'
import img3p24 from '../images/marketplace/prodotto24/3.jpg'
import img1p25 from '../images/marketplace/prodotto25/1.jpg'
import img2p25 from '../images/marketplace/prodotto25/2.jpg'
import img3p25 from '../images/marketplace/prodotto25/3.jpg'
import img1p26 from '../images/marketplace/prodotto26/1.jpg'
import img2p26 from '../images/marketplace/prodotto26/2.jpg'
import img3p26 from '../images/marketplace/prodotto26/3.jpg'
import img1p27 from '../images/marketplace/prodotto27/1.jpg'
import img2p27 from '../images/marketplace/prodotto27/2.jpg'
import img3p27 from '../images/marketplace/prodotto27/3.jpg'
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";

function Marketplace() {
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);


    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <main>
                    <Navbar />
                    <div id='bodyColor'>

                        <div class="container">
                            <header class="main-header clearfix">
                                <h1 class="name"><span>IL NOSTRO marketplace</span></h1>
                                <h6 style={{ marginBottom: '50px' }}>i migliori prodotti selezionati per te</h6>
                            </header>
                            <CubeMarketSection

                                title1="Numark Mixer"
                                text1="Numark Scratch - Mixer Scratch a 2 Canali per DJ + Serato DJ Pro, Crossfader Innofader, Licenza DVS, 6 Selettori per Effetti, Pad Performance"
                                img1p1={img1p2}
                                img2p1={img2p2}
                                img3p1={img3p2}
                                url1='https://www.amazon.it/dp/B07SNQXMLZ/?tag=macodj-21'

                                title2="BETOPPER Luci DJ"
                                text2="Luci Discoteca Luci Palco 54x3W RGBW Par Led Controllo Vocale/DMX (4 pezzi）"
                                img1p2={img1p1}
                                img2p2={img2p1}
                                img3p2={img3p1}
                                url2='https://www.amazon.it/dp/B08L7R6TY8/?tag=macodj-21'

                                title3="PreSonus Altoparlanti"
                                text3="PreSonus Eris E3.5 Altoparlanti Multimediali Monitor Da Studio, 3.5 Drivers, Nero"
                                img1p3={img1p3}
                                img2p3={img2p3}
                                img3p3={img3p3}
                                url3='https://www.amazon.it/dp/B075QVMBT9/?tag=macodj-21'
                            />
                            <CubeMarketSection
                                title1="Casse Monitor da Studio"
                                text1="M-Audio BX5 D3 -  100 W con Woofer da 5 + Stagg STC3 Cavo S Serie 2 x Jack Fono, Gemello RCAm a XLRm, 3m"
                                img1p1={img2p4}
                                img2p1={img1p4}
                                img3p1={img3p4}
                                url1='https://www.amazon.it/dp/B08S8RJBNP/?tag=macodj-21'

                                title2="Interfaccia audio USB"
                                text2="Scarlett 2i2 (terza generazione) di Focusrite per registrare e creare brani — registrazioni ad alta fedeltà in qualità studio, incluso tutto il software necessario per registrare"
                                img1p2={img1p5}
                                img2p2={img2p5}
                                img3p2={img3p5}
                                url2='https://www.amazon.it/dp/B07QR73T66/?tag=macodj-21'

                                title3="Apple AirPods"
                                text3="Apple AirPods con custodia di ricarica tramite cavo (seconda generazione)"
                                img1p3={img1p6}
                                img2p3={img2p6}
                                img3p3={img3p6}
                                url3='https://www.amazon.it/dp/B07PYMK77Y/?tag=macodj-21'
                            />
                            <CubeMarketSection
                                title1="Pioneer DM-40-W"
                                text1="Pioneer DM-40-W White Coppia Monitor attivi a 2 vie 4 /0,75 21 watt, Bianco"
                                img1p1={img1p7}
                                img2p1={img2p7}
                                img3p1={img3p7}
                                url1='https://www.amazon.it/dp/B01LY3EOT2/?tag=macodj-21'

                                title2="Numark Console DJ "
                                text2="Numark Mixtrack Pro FX – Console DJ a 2 decks per Serato DJ con mixer DJ, scheda audio integrata, jog wheel capacitive e palette FX"
                                img1p2={img1p8}
                                img2p2={img2p8}
                                img3p2={img3p8}
                                url2='https://www.amazon.it/dp/B087S28ZRM/?tag=macodj-21'

                                title3="SYNCO Microfono"
                                text3="SYNCO Microfono Lavalier Wireless, G2 A1 2.4GHz Microfoni Senza Fili Professionale per Fotocamera Reflex e Videocamera Smartphone iPhone/Android"
                                img1p3={img1p9}
                                img2p3={img2p9}
                                img3p3={img3p9}
                                url3='https://www.amazon.it/dp/B07PYMK77Y/?tag=macodj-21'
                            />
                            <CubeMarketSection
                                title1="Blue Yeti Microfono"
                                text1="Microfono USB per Registrazione, Streaming, Gaming, Podcasting su PC e Mac, Mic a Condensatore per Laptop o Computer, Effetti Blue VO!CE, Stand Regolabile Nero"
                                img1p1={img1p10}
                                img2p1={img2p10}
                                img3p1={img3p10}
                                url1='https://www.amazon.it/dp/B00N1YPXW2/?tag=macodj-21'

                                title2="Microfono dinamico"
                                text2="Microfono dinamico per voce con pattern polare a cardioide per l'uso professionale in applicazioni vocali dal vivo"
                                img1p2={img1p11}
                                img2p2={img2p11}
                                img3p2={img3p11}
                                url2='https://www.amazon.it/dp/B000CZ0R42/?tag=macodj-21'

                                title3="Tavolo per Computer portatile"
                                text3="Quik Lok DJ233 Tavolo per Computer portatile, ad altezza regolabile per DJ, Nero"
                                img1p3={img1p12}
                                img2p3={img2p12}
                                img3p3={img3p12}
                                url3='https://www.amazon.it/dp/B01E5GC32W/?tag=macodj-21'
                            />
                            <CubeMarketSection
                                title1="Edifier R1100"
                                text1="Edifier R1100 Sistema di Altoparlanti, Nero"
                                img1p1={img1p13}
                                img2p1={img2p13}
                                img3p1={img3p13}
                                url1='https://www.amazon.it/dp/B00MVSPKXG/?tag=macodj-21'

                                title2="M-Audio BX3 - Casse"
                                text2="Casse attive Monitor da Studio per PC Amplificate 120 W 3,5 (coppia)"
                                img1p2={img1p14}
                                img2p2={img2p14}
                                img3p2={img3p14}
                                url2='https://www.amazon.it/dp/B08KXVYGSF/?tag=macodj-21'

                                title3="Pioneer DJ – Cuffie"
                                text3="Cuffie Over-Ear Professionali Modello HDJ-X5 | Eccellente Qualità Sonora | Durata Superiore e Funzionalità Migliorate per DJ Professionisti – Nero"
                                img1p3={img1p15}
                                img2p3={img2p15}
                                img3p3={img3p15}
                                url3='https://www.amazon.it/dp/B075HCCMTW/?tag=macodj-21'
                            />
                            <CubeMarketSection
                                title1="Custodia per DJ"
                                text1="MAGMA 41100 Carry Lite - Custodia per DJ"
                                img1p1={img1p16}
                                img2p1={img2p16}
                                img3p1={img3p16}
                                url1='https://www.amazon.it/dp/B077C53DQY/?tag=macodj-21'

                                title2="TONOR Microfono wireless"
                                text2="Microfono wireless UHF professionale dinamico doppio microfono senza fili, sistema di microfono a mano, 60 metri"
                                img1p2={img1p17}
                                img2p2={img2p17}
                                img3p2={img3p17}
                                url2='https://www.amazon.it/dp/B07RKN9FF6/?tag=macodj-21'

                                title3="Fire TV Stick"
                                text3="Fire TV Stick con telecomando vocale Alexa (con comandi per la TV) | Streaming in HD"
                                img1p3={img1p18}
                                img2p3={img2p18}
                                img3p3={img3p18}
                                url3='https://www.amazon.it/dp/B08C1KN5J2/?tag=macodj-21'
                            />
                            <CubeMarketSection
                                title1="Interfaccia per chitarra"
                                text1="IK Multimedia iRig 2 - Interfaccia per chitarra e basso per iPhone e iPad"
                                img1p1={img1p19}
                                img2p1={img2p19}
                                img3p1={img3p19}
                                url1='https://www.amazon.it/dp/B00T631UTC/?tag=macodj-21'

                                title2="Absorber per monitor"
                                text2="Dam Hall Stands Pad Eco Serie spadeco2 - Absorber per monitor da studio, Grigio antracite, 26.5 x 33 x 4 cm, Set di 2"
                                img1p2={img1p20}
                                img2p2={img2p20}
                                img3p2={img3p20}
                                url2='https://www.amazon.it/dp/B007YFWUDO/?tag=macodj-21'

                                title3="ANNNWZZD Cavo XLR"
                                text3="ANNNWZZD Cavo XLR Bilanciato,Cavo per Microfono Professionale,XLR maschio a XLR femmina (10M)"
                                img1p3={img1p21}
                                img2p3={img2p21}
                                img3p3={img3p21}
                                url3='https://www.amazon.it/dp/B07R6LY1YG/?tag=macodj-21'
                            />
                            <CubeMarketSection
                                title1="Interfaccia USB/Audio"
                                text1="Behringer U-PHONO UFO202 Audiophile Interfaccia USB/Audio con Preamplificatore Phono Integrato per Digitalizzare Nastri e Dischi in Vinile"
                                img1p1={img1p22}
                                img2p1={img2p22}
                                img3p1={img3p22}
                                url1='https://www.amazon.it/dp/B002GHBYZ0/?tag=macodj-21'

                                title2="Behringer Xm8500"
                                text2="Behringer Xm8500, Microfono Per Voce, Dinamico E Cardioide, Nero"
                                img1p2={img1p23}
                                img2p2={img2p23}
                                img3p2={img3p23}
                                url2='https://www.amazon.it/dp/B0002KZAKS/?tag=macodj-21'

                                title3="Rockjam RJLP1"
                                text3="Rockjam RJLP1 Regolabile Desktop Desktop Desktop Stand Portapop per laptop Riser e supporto per controller DJ"
                                img1p3={img1p24}
                                img2p3={img2p24}
                                img3p3={img3p24}
                                url3='https://www.amazon.it/dp/B077V7LF7J/?tag=macodj-21'
                            />
                            <CubeMarketSection
                                title1="Chiaro Luce "
                                text1="Pulizia Spray igienizzante Multisuperficie, ideale per tutte le superfici."
                                img1p1={img1p25}
                                img2p1={img2p25}
                                img3p1={img3p25}
                                url1='https://www.amazon.it/dp/B01LF8QPYK/ref=cm_sw_r_as_gl_apa_gl_i_VJE81ZGGSFXF0RDAMPQQ?linkCode=ml1&tag=macodj-21'

                                title2="Svitol Lubrificante "
                                text2="Spray lubrificante erogatore con cannuccia, riattiva conducibilità elettrica e meccanismi bloccati."
                                img1p2={img1p26}
                                img2p2={img2p26}
                                img3p2={img3p26}
                                url2='https://www.amazon.it/dp/B00CVZTDOG/ref=cm_sw_r_as_gl_apa_gl_i_DW33D59Z0W8HV2J1VN9H?linkCode=ml1&tag=macodj-21'

                                title3="Panni in microfibra"
                                text3="EVO Set 4 Panni in Microfibra, per la Pulizia di Vetri, PC, Smartphone, ultra assorbenti e resistenti, riutilizzabili a lunga durata cm 55x55"
                                img1p3={img1p27}
                                img2p3={img2p27}
                                img3p3={img3p27}
                                url3='https://www.amazon.it/dp/B0BZT5JKJM/ref=cm_sw_r_as_gl_apa_gl_i_H4JEPB1KK4GV0T2FEYQR?linkCode=ml1&tag=macodj-21'
                            />
                        </div>
                        <div id='contact' data-aos="fade-up">
                            <Contact />
                        </div>
                        <Footer />
                    </div >
                </main>
            }
        </div>
    )
}

export default Marketplace;