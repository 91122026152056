import imgHome1 from '../images/tutorial/img1.webp'
import imgHome2 from '../images/tutorial/img2.webp'
import imgHome3 from '../images/tutorial/img3.webp'
import imgHome4 from '../images/tutorial/img4.webp'
import Contact from '../components/contact/contact'
import 'aos/dist/aos.css'
import '../style/servizi.css'
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import ContactButton from '../components/buttonContact'
import Logo from '../images/logo2.png'
import AOS from 'aos';
import { useEffect } from "react"
import 'aos/dist/aos.css'
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom'
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";

function Tutorial() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);

    const videoTitle = "Tutorial";
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <div id='bodyColor'>
                    <Navbar />
                    <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
                        <div className="row"  >
                            <img alt="header" id='firstImg' src={imgHome1} style={{ maxHeight: '1400px', maxWidth: '100%', right: '3px', position: 'absolute', opacity: 0.5, marginTop: '30px' }} className="card-img-top" ></img>
                            <div className="col-lg-1">
                                <div></div>
                            </div>
                            <div className="col-lg-10" id='marginContact' style={{ justifyContent: 'center', justifyItems: 'center' }}>
                                <ul>
                                    <li><img src={Logo} style={{ width: '600px' }} ></img></li>
                                    <li><h4 className="display-5 fw-normal" style={{ color: 'white', marginBottom: '30px' }}>TUTORIALS</h4></li>
                                    <li><ContactButton /></li>
                                </ul>
                            </div>
                            <div className="col-lg-1 " >
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div className=" row col featurette" id="mainElement" >
                        <div className="col-md-1"></div>
                        <div className=" col-md-10"  >
                            <div className="row featurette test" >
                                <div className="col-md-5">
                                    <h2 className="featurette-heading fw-normal lh-1" data-aos="fade-right" data-aos-duration="3000" style={{ color: "rgb(0,225,72)", textAlign: 'left', marginTop: '25%' }}>TUTORIAL MIX</h2>
                                    <p className="lead " id="matrimonio-text" data-aos="fade-right" data-aos-duration="3000" >Scopri come diventare un DJ professionista con i nostri tutorial dettagliati e facili da seguire!</p>
                                </div>
                                <div className="col-md-7">
                                    <Link to={'https://www.youtube.com/playlist?list=PLBerUhZC226eaxEkbRXdq1Q8CsiRu7-aY'}><img alt="img1" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" data-aos="fade-right" data-aos-duration="2000" style={{ width: '900px' }} src={imgHome2} role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"></img></Link>
                                </div>
                            </div>
                            <div className="row featurette" id="rowElement" >
                                <div className="col-md-5 order-md-2" >
                                    <h2 className="featurette-heading fw-normal lh-1" data-aos="fade-right" data-aos-duration="3000" style={{ color: "rgb(0,225,72)", textAlign: 'left', marginTop: '25%' }}>TUTORIAL ATTREZZATURE AUDIO</h2>
                                    <p className="lead" id="matrimonio-text" data-aos="fade-right" data-aos-duration="3000" >Abbiamo creato dei tutorial passo-passo per aiutarti ad apprendere le basi della discografia, del beatmatching e molto altro ancora.</p>
                                </div>
                                <div className="col-md-7">
                                    <Link to={'https://www.youtube.com/playlist?list=PLBerUhZC226dGgG4HmH_1q4Sy6Lx3__3D'}><img alt="img2" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" data-aos="fade-right" data-aos-duration="2000" style={{ width: '900px' }} src={imgHome3} role="img" aria-label="Placeholder: 500x500" focusable="false"></img></Link>
                                </div>
                            </div>
                            <div className="row featurette " id="lastElement" >
                                <div className="col-md-5">
                                    <h2 className="featurette-heading fw-normal lh-1" data-aos="fade-right" data-aos-duration="3000" style={{ color: "rgb(0,225,72)", textAlign: 'left', marginTop: '25%' }}>TUTORIAL SIAE</h2>
                                    <p className="lead" id="matrimonio-text" data-aos="fade-right" data-aos-duration="3000">Segui i tutorial per sapere tutto il necessario sul permesso SIAE online</p>
                                </div>
                                <div className="col-md-7">
                                    <Link to={'https://www.youtube.com/playlist?list=PLBerUhZC226cRR8YHXZmzpsPds01L-d44'}><img alt="img3" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" data-aos="fade-right" data-aos-duration="2000" style={{ width: '900px' }} src={imgHome4} role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"></img></Link>
                                </div>
                                <div class="container px-4 py-5" id='seguici'>
                                    <h3 class="pb-2 border-bottom" style={{ color: "white" }} >SEGUI I NOSTRI TUTORIAL SU YOUTUBE <SocialIcon url='https://www.youtube.com/@MACODJ/playlists' style={{ margin: '10px', width: '55px', height: '55px' }} /></h3>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-1"></div>
                    </div>

                    <div id='contact' data-aos="fade-up">
                        <Contact />
                    </div>
                    <Footer />
                </div>
            }
        </div>
    )
}

export default Tutorial;