import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Contact from '../components/contact/contact';
import Logo from '../images/logo2.png'
import ContactButton from "../components/buttonContact";
import AOS from 'aos';
import { useEffect } from "react"
import 'aos/dist/aos.css'
import '../style/servizi.css'
import { ToastContainer} from 'react-toastify';

function Servizi(props) {
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);
    return (
        <main>
            <Navbar />
            <ToastContainer/>
            <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
                <div className="row"  >
                    <img alt="header" id='firstImg' src={props.headerImg}  className="card-img-top" ></img>
                    <div className="col-lg-1">
                        <div></div>
                    </div>
                    <div className="col-lg-10" id='marginContact' style={{ justifyContent: 'center', justifyItems: 'center' }}>
                        <ul>
                            <li><img src={Logo} style={{ width: '600px' }} ></img></li>
                            <li><h4 className="display-5 fw-normal" style={{ color: 'white', marginBottom:'30px' }}>{props.title}</h4></li>
                            <li><ContactButton /></li>
                        </ul>
                    </div>
                    <div className="col-lg-1 " >
                        <div></div>
                    </div>
                </div>
            </div>
            <div className=" row col featurette" id="mainElement" >
                <div className="col-md-1"></div>
                <div className=" col-md-10"  >
                    <div className="row featurette test" >
                        <div className="col-md-5">
                            <h2 className="featurette-heading fw-normal lh-1" data-aos="fade-right" data-aos-duration="3000" style={{ color: "rgb(0,225,72)", textAlign: 'left', marginTop: '25%' }}>{props.title1}</h2>
                            <p className="lead   " id="matrimonio-text" data-aos="fade-right" data-aos-duration="3000" >{props.text1}</p>
                        </div>
                        <div className="col-md-7">
                            <img alt="maco dj bergamo" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" data-aos="fade-right" data-aos-duration="2000" style={{ width: '900px' }} src={props.img1} role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"></img>
                        </div>
                    </div>
                    <div className="row featurette "  id="rowElement" >
                        <div className="col-md-5 order-md-2" >
                            <h2 className="featurette-heading fw-normal lh-1" data-aos="fade-right" data-aos-duration="3000" style={{ color: "rgb(0,225,72)", textAlign: 'left', marginTop: '25%' }}>{props.title2}</h2>
                            <p className="lead" id="matrimonio-text" data-aos="fade-right" data-aos-duration="3000" >{props.text2}</p>
                        </div>
                        <div className="col-md-7">
                            <img alt="maco dj bergamo" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" data-aos="fade-right" data-aos-duration="2000" style={{ width: '900px' }} src={props.img2} role="img" aria-label="Placeholder: 500x500" focusable="false"></img>
                        </div>
                    </div>
                    <div className="row featurette " id="lastElement" >
                        <div className="col-md-5">
                            <h2 className="featurette-heading fw-normal lh-1" data-aos="fade-right" data-aos-duration="3000" style={{ color: "rgb(0,225,72)", textAlign: 'left', marginTop: '25%' }}>{props.title3}</h2>
                            <p className="lead " id="matrimonio-text" data-aos="fade-right" data-aos-duration="3000" >{props.text3}</p>
                        </div>
                        <div className="col-md-7">
                            <img alt="maco dj bergamo" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" data-aos="fade-right" data-aos-duration="2000" style={{ width: '900px' }} src={props.img3} role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"></img>
                        </div>
                    </div>
                </div>
                <div className="col-md-1"></div>
            </div>
            <div id='contact'>
                <Contact />
            </div>
            <Footer />
        </main>
    )
}

export default Servizi;