
import imgHome1 from '../images/matrimonio/matrimoniopage.webp'
import imgHome2 from '../images/matrimonio/matrimoniopage2.webp'
import imgHome3 from '../images/matrimonio/matrimoniopage3.webp'
import imgHome4 from '../images/matrimonio/matrimoniopage4.webp'
import imgHome5 from '../images/matrimonio/matrimoniopage5.webp'
import 'aos/dist/aos.css';
import '../style/servizi.css';
import Servizi from "../components/servizi";
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react"

function Matrimonio() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :

                <div id='bodyColor'>
                    <Servizi
                        headerImg={imgHome1}
                        title='MATRIMONIO'
                        title1='IL TUO MATRIMONIO PERFETTO'
                        text1="Ogni matrimonio è diverso,  il nostro obiettivo è quello di creare un'esperienza musicale personalizzata che rispecchi lo stile e la personalità della coppia. Conoscere i gusti dei nostri clienti è fondamentale per offrire un servizio su misura, dall'accompagnamento musicale della cerimonia alla selezione dei brani per il ricevimento."
                        img1={imgHome3}
                        title2='ATMOSFERA MAGICA'
                        text2="Effetti luci e audio professionali per creare un’atmosfera magica e confortevole."
                        img2={imgHome2}
                        title3='RICORDI SPECIALI'
                        text3="Catturare gli attimi più belli del vostro matrimonio è il nostro obiettivo, per regalarvi ricordi unici e indelebili, per questo mettiamo a disposizione un servizio fotografico."
                        img3={imgHome5}

                    />
                </div>
            }
        </div>

    )
}

export default Matrimonio;