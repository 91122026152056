import * as React from 'react';
import '../style/marketplace.css'
import Logo from '../images/Amazon_logo.png'

function CubeMarket (props) {
    return (
        <div class="cube-container">
        <div class="photo-cube">
            <img class="front" src={props.img1} alt="First img"></img>
            <div class="back photo-desc">
                <h3>{props.title}</h3>
                <p className='p_market'>{props.text}</p>
                <p className='p_market'>{props.price}</p>
                <a href={props.product} className="button"> <img style={{width:'20px'}} src={Logo}></img> Acquista ora</a>
            </div>
            <img class="left" src={props.img2} alt="Second img"></img>
            <img class="right" src={props.img3} alt="Third img"></img>
        </div>
    </div>
    )
}

export default CubeMarket;