import './css/style.css';
import './css/animate.css';
import './css/button.css';
import imgContact from './images/contact.webp';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import React from "react";
import {toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import img2 from './images/img2.webp';
import Box from '@mui/material/Box';
import 'react-toastify/dist/ReactToastify.css';

class Contact extends React.Component {


    render() {



        return (
            <section class="ftco-section img bg-hero" style={{ boxShadow: '2px 2px 30px black', backgroundImage: `url(${img2})` }}>
                <div class="container">
                    <div class="row justify-content-center" >
                        <div class="col-md-12">
                            <div class="wrapper" >
                                <div class="row no-gutters">
                                    <div class="col-md-7" >
                                        <Box>
                                            <div class="contact-wrap w-100 p-md-5 p-4" id='contactColorForm' style={{ background: 'rgba(0,0,0,0.9)' }} >
                                                <div class="col-md-6 text-center mb-5">
                                                    <h1 class="display-5" style={{ color: 'white' }} >CONTATTACI</h1>
                                                </div>
                                                <form id="contactForm" name="contactForm" className="contactForm" onSubmit={this.sendEmail} >
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <div className="form-group">
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="name"
                                                                    variant='filled'
                                                                    label="Nome"
                                                                    name="name"
                                                                    autoComplete="given-name"
                                                                    sx={{ label: { color: 'white' }, input: { color: 'white' } }}
                                                                    focused
                                                                    color='success'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="form-group">
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    type="email"
                                                                    id="email"
                                                                    label="Email"
                                                                    name="email"
                                                                    autoComplete="email"
                                                                    variant="filled"
                                                                    color='success'
                                                                    sx={{ label: { color: 'white' }, input: { color: 'white' } }}
                                                                    focused
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="form-group">
                                                                <TextField
                                                                    fullWidth
                                                                    id="number"
                                                                    label="Numero telefonico"
                                                                    name="number"
                                                                    autoComplete="number"
                                                                    variant="filled"
                                                                    color='success'
                                                                    sx={{ label: { color: 'white' }, input: { color: 'white' } }}
                                                                    focused
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="form-group">
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="subject"
                                                                    label="Oggetto"
                                                                    name="subject"
                                                                    variant="filled"
                                                                    color='success'
                                                                    sx={{ label: { color: 'white' }, input: { color: 'white' } }}
                                                                    focused
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-10">
                                                            <div className="form-group">
                                                                <Grid item xs={16} sm={12}>
                                                                    <TextField
                                                                        multiline
                                                                        placeholder='DATA, LOCATION, ORARI'
                                                                        required
                                                                        fullWidth
                                                                        id="outlined-multiline-static"
                                                                        label="Messagio"
                                                                        name="message"
                                                                        variant="filled"
                                                                        color='success'
                                                                        success
                                                                        rows={4}
                                                                        focused
                                                                        inputProps={{
                                                                            style: {
                                                                                color: 'white',
                                                                                height: "200px",
                                                                            },
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="form-group">
                                                                <button
                                                                    className='btnr'
                                                                    type="submit"
                                                                    fullWidth
                                                                    color="success"
                                                                    variant="contained"
                                                                > INVIA
                                                                </button>
                                                                <div className="submitting"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </Box>
                                    </div>
                                    <div class="col-md-5 d-flex">
                                        <img alt='foto dj maco' src={imgContact}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        )
    };
    sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_9p3tcax", "template_whm38qr", e.target, "bVTXbSRzIiymzhjzY")
            .then((result) => {
                toast.success("Email Inviata");
                console.log(result.text);
            }, (error) => {
                toast.error("Errore");
                console.log(error.text);
            });
        e.target.reset()
    }
}
export default Contact;
