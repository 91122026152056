import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Siae from "../images/siae.webp"
import { Link } from "react-router-dom";
import '../style/siae.css'
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react"




function Siaee() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <div id='bodyColor' >
                    <Navbar />
                    <Link to="https://www.siae.it/it/"><img alt="siae maco dj bergamo" src={Siae} width='40%' id="siaeImg" className="zoom" style={{ maxHeight: '400px', marginBottom: '100px', marginTop: '100px' }}></img></Link>
                    <div className=" row col featurette">
                        <div className="col-md-2"></div>
                        <div className=" col-md-8"  >
                            <div class="row featurette test" >
                                <div class="col-md-12">
                                    <h2 class="featurette-heading fw-normal lh-1" style={{ color: "skyblue", textAlign: 'left' }}>SIAE Società Italiana degli Autori ed Editori</h2>
                                    <p class="lead text" id="chi-siamo-text" style={{ textAlign: 'left' }}>SE STAI ORGANIZZANDO UNA FESTA PRIVATA O UN MATRIMONIO CON DEEJAY, DOVRAI TENER PRESENTE CHE È NECESSARIO RICHIEDERE UN PERMESSO SIAE PER POTER DISPORRE DI UN SOTTOFONDO MUSICALE.
                                        IN QUESTA PICCOLA GUIDA TI AIUTEREMO A COMPRENDERE COS’È LA SIAE, COME MAI DOVRAI TENERLA IN CONSIDERAZIONE PER L’ORGANIZZAZIONE DEL TUO EVENTO, E COME FARE PER OTTENERE UN PERMESSO E PROCEDERE AL PAGAMENTO.
                                        SIAE È L’ACRONIMO DI SOCIETÀ ITALIANA DEGLI AUTORI ED EDITORI, UN ENTE PUBBLICO ECONOMICO A BASE ASSOCIATIVA. IL SUO SCOPO È QUELLO DI PROTEGGERE ED ESERCITARE L’INTERMEDIAZIONE DEL DIRITTO D’AUTORE IN ITALIA.
                                        AUTORI, EDITORI ED ALTRI TITOLARI DI DIRITTI D’AUTORE, VI ADERISCONO VOLONTARIAMENTE PER TUTELARE ECONOMICAMENTE LE LORO CREAZIONI.
                                        IN ALTRE PAROLE, LO SCOPO DELLA SIAE È QUELLO DI ASSICURARE AI DIRETTI INTERESSATI LA REMUNERAZIONE DEL LORO LAVORO.
                                        NEL NOSTRO CASO, L’AUTORE DI UN BRANO MUSICALE AFFIDA LA TUTELA DEI PROPRI DIRITTI SUL BRANO ALLA SIAE, CHE RACCOGLIE LE SOMME SPETTANTI E GLIELE VERSA.
                                    </p>
                                </div>
                                <div class="col-md-6">
                                </div>
                            </div>
                            <div class="row featurette" style={{ marginTop: '60px' }}>
                                <div class="col-md-12 order-md-2">
                                    <h2 class="featurette-heading fw-normal lh-1" style={{ color: "skyblue", textAlign: 'left' }}>Coma mai è necessaria una licenza SIAE?</h2>
                                    <p class="lead" id="chi-siamo-text" style={{ textAlign: 'left' }}>IN TUTTI QUEI CASI IN CUI SI ORGANIZZA UN EVENTO O UNA FESTA PRIVATA (AD ESEMPIO MATRIMONI, CRESIME, COMPLEANNI, IN GENERALE QUALUNQUE EVENTO ORGANIZZATO IN UN LUOGO DIFFERENTE DALLA PROPRIA ABITAZIONE) E VIENE ESEGUITA MUSICA ALTRUI, È NECESSARIA UNA LICENZA SIAE.
                                        PER FARTI QUALCHE ESEMPIO, DURANTE UN MATRIMONIO SOLITAMENTE VENGONO RIPRODOTTI BRANI MUSICALI DI VARIO GENERE, GRAZIE ALLA PRESENZA DI UNA BAND, DI UN CANTANTE, TRAMITE UN SERVIZIO DEEJAY CON TUTTA LA STRUMENTAZIONE DEL CASO, O SEMPLICEMENTE ATTRAVERSO L’UTILIZZO DI UN PC CON DELLE CASSE.
                                        CIÒ CHE ACCOMUNA QUESTE SITUAZIONI È IL FATTO CHE VIENE RIPRODOTTA MUSICA ALTRUI, NEL CORSO DI UN EVENTO, ORGANIZZATO IN UN LUOGO DIFFERENTE RISPETTO ALLA PROPRIA ABITAZIONE.
                                        PER VERIFICARE SE IL TUO REPERTORIO MUSICALE APPARTIENE AL REPERTORIO MONITORATO E PROTETTO DALLA SIAE PUOI <strong>VISITARE QUESTO LINK:</strong> <br />
                                        <Link to='https://www.siae.it/it/utilizzatori/feste-private/'>ARCHIVIO OPERE SIAE</Link> <br />
                                        SELEZIONANDO REPERTORIO “MUSICA”, POTRAI VERIFICARE SE CIÒ CHE INTENDI RIPRODURRE APPARTIENE AL REPERTORIO SIAE.
                                        IN TUTTI QUEI CASI IN CUI ORGANIZZI UN EVENTO PRIVATO PRESSO LA TUA RESIDENZA RIPRODUCENDO BRANI NON APPARTENENTI AL REPERTORIO SIAE, NON SARÀ NECESSARIA LA LICENZA.</p>
                                </div>
                            </div>
                            <div class="row featurette" style={{ marginTop: '60px' }}>
                                <div class="col-md-12 order-md-2">
                                    <h2 class="featurette-heading fw-normal lh-1" style={{ color: "skyblue", textAlign: 'left' }}>Diritti connessi, cosa sono?</h2>
                                    <p class="lead" id="chi-siamo-text" style={{ textAlign: 'left' }} >I DIRITTI CONNESSI AL DIRITTO D’AUTORE SONO DIRITTI CHE IL LEGISLATORE RICONOSCE AI SOGGETTI COLLEGATI ALL’AUTORE DELL’OPERA. NEL DETTAGLIO SI FA RIFERIMENTO AGLI INTERPRETI O ESECUTORI, ARTISTI, PRODUTTORI DI SUPPORTI FONOGRAFICI, PRODUTTORI DI OPERE AUDIOVISIVE O CINEMATOGRAFICHE, EMITTENTI TELEVISIVE, RADIOFONICHE ECC. SONO TUTTI COLORO CHE OFFRONO L’OPERA AL BENEFICIO DEL PUBBLICO E SONO ANCH’ESSI DETENTORI DI DIRITTI PATRIMONIALI E (TALVOLTA) MORALI, COME AD ESEMPIO ARTISTI INTERPRETI O ESECUTORI.
                                        ORA CHE ABBIAMO CAPITO COSA FA LA SIAE E PERCHÈ RICHIEDE DETERMINATI PERMESSI PER POTER RIPRODURRE MUSICA REGISTRATA O DAL VIVO, ENTRIAMO NEL VIVO DELLA QUESTIONE CERCANDO DI CAPIRE COME OTTENERE LA LICENZA E SOPRATTUTTO, COME PAGARLI.</p>
                                </div>
                                <div class="col-md-5">
                                </div>
                            </div>
                            <div class="row featurette " style={{ marginTop: '60px' }}>
                                <div class="col-md-12 order-md-2">
                                    <h2 class="featurette-heading fw-normal lh-1" style={{ color: "skyblue", textAlign: 'left' }}>Facciamo chiarezza, quando si parla di festa o evento privato?</h2>
                                    <p class="lead" id="chi-siamo-text" style={{ textAlign: 'left' }} >RIENTRANO IN QUESTA CATEGORIA LE FESTE AVENTI CARATTERE PRIVATO (COMPLEANNI, FESTE DI LAUREA, BATTESIMI, MATRIMONI, CRESIME, E ANALOGHI FESTEGGIAMENTI) OFFERTI DA SOGGETTI PRIVATI E RISERVATI AI SOLI INVITATI.
                                        NEL CASO IN CUI LA MUSICA NON FOSSE DAL VIVO (PRESENZA DI UNA BAND O UN CANTANTE AD ESEMPIO) MA REGISTRATA, OLTRE AL COMPENSO PER IL DIRITTO D’AUTORE È DOVUTO ANCHE UN COMPENSO PER I COSIDDETTI DIRITTI CONNESSI, SPETTANTI AI PRODUTTORI DEI SUPPORTI FONOGRAFICI ED AGLI ARTISTI INTERPRETI O ESECUTORI DEI BRANI.
                                        PER MUSICA REGISTRATA SI INTENDE MEDIANTE LA DIFFUSIONE DI CD, USB, DVD, ECC. SIA QUELLA DIFFUSA MEDIANTE UN DISC JOCKEY O SIMILARI.</p>
                                </div>
                            </div>
                            <div class="row featurette " style={{ marginTop: '60px' }}>
                                <div class="col-md-12">
                                    <h2 class="featurette-heading fw-normal lh-1" style={{ color: "skyblue", textAlign: 'left' }}>Come ottenere un permesso SIAE</h2>
                                    <p class="lead" id="chi-siamo-text" style={{ textAlign: 'left' }} ><strong>AL SEGUENTE LINK </strong><Link to='https://www.siae.it/it/'>SERVIZI ONLINE</Link> È POSSIBILE ACCEDERE AL PORTALE ONLINE DEDICATO ALLE FESTE PRIVATE, DOVE È POSSIBILE OTTENERE LA LICENZA PER IL TUO EVENTO CON ESECUZIONE DI BRANI MUSICALI TUTELATI DALLA SIAE. OLTRE A CIÒ SARÀ POSSIBILE PAGARE IL COMPENSO DOVUTO PER IL DIRITTO D’AUTORE E DIRITTI ACCESSORI (DIRITTI AMMINISTRATIVI DI PROCEDURA, EVENTUALI DIRITTI CONNESSI E IVA DI FATTURA).
                                        REQUISITI NECESSARI PER PROCEDERE ALLA RICHIESTA DEL PERMESSO, È CHE IL RICHIEDENTE SIA UNA PERSONA FISICA MAGGIORENNE E CHE SIA L’ORGANIZZATORE DELL’EVENTO. IL PAGAMENTO DELLA QUOTA SPETTANTE ALLA SIAE SARÀ DOVUTO ALL’ATTO DEL RILASCIO DEL PERMESSO. PER MAGGIORI INFORMAZIONI IN MERITO ALLE TARIFFE POTETE <strong>VISITARE IL SEGUENTE LINK</strong> <Link to='https://www.siae.it/it/utilizzatori/feste-private/'>TARIFFE SIAE</Link>.
                                        SE HAI ANCORA DUBBI, PUOI SEGUIRE IL MIO CANALE YOUTUBE, DOVE TI SPIEGO PASSO PASSO COME PROCEDERE PER L’OTTENIMENTO DEL PERMESSO SIAE PER MATRIMONIO. NEL DETTAGLIO HO PREPARATO UN VIDEO AD HOC PER VOI.</p>
                                    <div class="iframe-container">
                                        <iframe src='https://www.youtube.com/embed/Ackns9tC_E0'
                                            allow='autoplay; encrypted-media'
                                            allowfullscreen
                                            title='video'
                                        />

                                    </div>
                                </div>
                                <div class="col-md-5">

                                </div>
                            </div>
                            <div class="row featurette" style={{ marginTop: '60px', marginBottom: '60px' }}>
                                <div class="col-md-12 order-md-2">
                                    <h2 class="featurette-heading fw-normal lh-1" style={{ color: "skyblue", textAlign: 'left' }}>Come richiedere un rimborso alla SIAE per evento annullato</h2>
                                    <p class="lead" id="chi-siamo-text" style={{ textAlign: 'left' }} >ORA CHE ABBIAMO VISTO TUTTI I PASSAGGI PER RICHIEDERE UNA LICENZA SIAE PER IL NOSTRO EVENTO SPECIALE, NON RESTA CHE VERIFICARE LE POSSIBILITÀ CHE ABBIAMO NEL CASO IN CUI, PURTROPPO, IL NOSTRO EVENTO DEBBA ESSERE ANNULLATO, DOPO AVER GIÀ EFFETTUATO IL PAGAMENTO VERSO LA SIAE.
                                        VEDIAMO QUINDI, COME OTTENERE UN RIMBORSO. PAGAMENTO ANTICIPATO ONLINE IN QUESTO CASO I PASSAGGI DA SEGUIRE SONO MOLTO SEMPLICI, È SUFFICIENTE INVIARE LA RICHIESTA DI RIMBORSO ALL’INDIRIZZO EMAIL RIMBORSI SIAE, ACCOMPAGNATA DA COPIA FRONTE/RETRO DI UN DOCUMENTO DI IDENTITÀ DELL’INTESTATARIO DEL PERMESSO E IL NUMERO DEL PERMESSO RILASCIATO.
                                        PAGAMENTO PRESSO UN UFFICIO SIAE L’ORGANIZZATORE DELL’EVENTO DOVRÀ INVIARE ALL’INDIRIZZO EMAIL DELL’UFFICIO CHE HA RILASCIATO IL PERMESSO (INDICATO NELLE CONDIZIONI PARTICOLARI DEL PERMESSO), LA RICHIESTA DI RIMBORSO, ACCOMPAGNATA DA UNA COPIA FRONTE/RETRO DEL DOCUMENTO DI IDENTITÀ E DEL NUMERO DEL PERMESSO RILASCIATO.
                                        IN QUESTO CASO SARÀ NECESSARIO INDICARE L’IBAN NEL QUALE LA SIAE EFFETTUERÀ IL RIMBORSO. PER POTER RICERCARE L’INDIRIZZO EMAIL DELL’UFFICIO COMPETENTE POTETE RECARVI SUL SITO UFFICI SIAE ITALIA.
                                        PAGAMENTO TRAMITE IL PORTALE UTILIZZATORI PROFESSIONALI (POP) I PASSAGGI DA SEGUIRE SARANNO I MEDESIMI DEL PUNTO APPENA SOPRA. PER MAGGIORI INFORMAZIONI POTETE <strong>VISITARE IL SEGUENTE LINK:</strong> <Link to='https://www.siae.it/it/autori-editori/copia-privata/'>RIMBORSI SIAE.</Link><br></br>
                                        INOLTRE VI INVITO A VISITARE IL MIO CANALE YOUTUBE DOVE HO CREATO UN VIDEO APPOSTA PER VOI CON TUTTI I DETTAGLI IN MERITO ALLA RICHIESTA DEL RIMBORSO, <strong>LO TROVATE AL SEGUENTE LINK:<br></br></strong> <Link to='https://www.youtube.com/watch?v=9nwwSeVseW8'>COME RICHIEDERE UN RIMBORSO ALLA SIAE PER EVENTO ANNULLATO</Link>.<br></br>
                                        <strong> PER MAGGIORI INFORMAZIONI CONTATTACI AL 3342941917 O SCRIVICI UNA MAIL A MACODEEJAY@GMAIL.COM</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <Footer />
                </div>
            }
        </div>
    )
}

export default Siaee;