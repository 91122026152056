import imgHome1 from '../images/festedilaurea/img1.webp'
import imgHome2 from '../images/festedilaurea/img2.webp'
import imgHome3 from '../images/festedilaurea/img3.webp'
import imgHome4 from '../images/festedilaurea/img4.webp'
import 'aos/dist/aos.css'
import '../style/servizi.css'
import Servizi from "../components/servizi";
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react"

function Festedilaurea() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <div id='bodyColor'>
                    <Servizi
                        headerImg={imgHome1}
                        title='LAUREA'
                        title1='DJ PROFESSIONALE'
                        text1="Se vuoi una festa di laurea che i tuoi amici non dimenticheranno mai, non cercare oltre, scegli un DJ professionista!"
                        img1={imgHome2}
                        title2='MIX ESPOLIVI'
                        text2="La nostra musica è l'ingrediente segreto per una festa di laurea perfetta. Con i nostri mix esplosivi, faremo ballare tutto il pubblico fino a notte inoltrata!"
                        img2={imgHome3}
                        title3='MUSICA PERSONALIZZATA'
                        text3="Playlist personalizzate apposta per voi che vi garantiranno un evento unico."
                        img3={imgHome4}
                    />
                </div>
            }
        </div>
    )
}

export default Festedilaurea;