import imgHome1 from '../images/festeaziendali/img1.webp'
import imgHome2 from '../images/festeaziendali/img2.webp'
import imgHome3 from '../images/festeaziendali/img3.webp'
import imgHome4 from '../images/festeaziendali/img4.webp'
import 'aos/dist/aos.css'
import '../style/servizi.css'
import Servizi from "../components/servizi";
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react"

function EventiAziende() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <div id='bodyColor'>
                    <Servizi
                        headerImg={imgHome1}
                        title='EVENTI AZIENDALI'
                        title1='UN GRANDE EVENTO'
                        text1="Con il nostro servizio di DJ per eventi aziendali, puoi garantire l'entusiasmo di tutti i tuoi ospiti."
                        img1={imgHome2}
                        title2='ATMOSFERA PERFETTA'
                        text2="Non esitare a scegliere il nostro servizio di DJ per il tuo prossimo evento aziendale e renderlo qualcosa di unico. Servizio fotografico e d'intrattenimento con spettacolo incluso"
                        img2={imgHome3}
                        title3='SUCCESSO GARANTITO'
                        text3="Metti la musica al primo posto con il nostro servizio di DJ per eventi aziendali."
                        img3={imgHome4}
                    />
                </div>
            }
        </div>
    )
}

export default EventiAziende;