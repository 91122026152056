import Card from "./card"

const CardSection = (props) => {
    return (

        <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
            <div data-aos="fade-up">
                <Card name={props.name1} img={props.img1} location={props.location1} text={props.text1} />
            </div>
            <div data-aos="fade-up">
                <Card name={props.name2} img={props.img2} location={props.location2} text={props.text2} />
            </div>
        </div>
    )
}

export default CardSection;