
import imgHome1 from '../images/chisiamo/img1.webp'
import imgHome2 from '../images/chisiamo/img2.webp'
import imgHome3 from '../images/chisiamo/img3.webp'
import imgHome4 from '../images/chisiamo/img4.webp'
import 'aos/dist/aos.css'
import '../style/servizi.css'
import Servizi from "../components/servizi";
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react"

function Chisiamo() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <div id='bodyColor'>
                    <Servizi
                        headerImg={imgHome1}
                        title='CHI SIAMO'
                        title1="L'INIZIO"
                        text1="La mia passione per la musica è iniziata sin dall&#39;infanzia, quando ho iniziato a suonare la chitarra e
                        sviluppare la mia creatività. Con il tempo ho scoperto la mia vera passione per l&#39;arte del DJ per
                        eventi e ho deciso di dedicarmi completamente a questa forma d&#39;arte. Ho lavorato in molti
                        contesti come DJ per eventi, da feste private a battesimi, e ho acquisito la capacità di far ballare e
                        divertire i miei clienti, rendendo ogni evento indimenticabile."
                        img1={imgHome2}
                        title2='LA MIA ESPERIENZA'
                        text2="Sono sempre alla ricerca di nuove tendenze e musica innovativa per assicurare un repertorio da DJ
                        per eventi sempre fresco e interessante. Tuttavia, suonerò spesso i grandi classici che hanno fatto
                        ballare diverse generazioni nel corso degli anni. Sono un DJ per eventi appassionato, professionale
                        ed esperto, e la mia priorità è quella di soddisfare appieno i miei clienti, garantendo loro un
                        evento unico ed indimenticabile."
                        img2={imgHome3}
                        title3='STRUMENTAZIONE TECNICA'
                        text3="Lavoro con attrezzature audio e luci di alta qualità e fornisco una consulenza professionale per
                        ogni tipo di evento, personalmente oppure in videochiamata, al fine di garantire un evento
                        impeccabile e senza intoppi."
                        img3={imgHome4}
                    />
                </div>
            }
        </div>
    )
}

export default Chisiamo;