import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import * as React from 'react';


function buttonDrawer (props) {
    return (
        <Link to={props.location}><Button  size='large' sx={{
            color:'white',
            height:'55px',
            width:'150px',

            '&:hover': {
                boxShadow: '2px 2px 9px black',
                color:'rgb(0,225,72)',
                borderBottom: '2px solid rgb(0, 225, 72)',

                
            },
           
        }} >{props.name}</Button></Link>
    )
}

export default buttonDrawer;

