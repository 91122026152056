
import imgHome1 from '../images/aperitivo/img1.webp'
import imgHome2 from '../images/aperitivo/img2.webp'
import imgHome3 from '../images/aperitivo/img3.webp'
import imgHome4 from '../images/aperitivo/img4.webp'
import Servizi from "../components/servizi";
import 'aos/dist/aos.css'
import '../style/servizi.css'
import { useEffect } from "react"
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
function Aperitivi() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <div id='bodyColor'>
                    <Servizi
                        headerImg={imgHome1}
                        title='APERITIVO'
                        title1='APERITIVO IN GRANDE STILE '
                        text1="Per un aperitivo di successo, serve la giusta selezione musicale. Lascia che i nostri mix siano il tocco perfetto per la tua serata."
                        img1={imgHome2}
                        title2='RELAX'
                        text2="L'aperitivo è il momento ideale per rilassarsi con gli amici e ascoltare la giusta musica. Playlist personalizzate per il tuo evento."
                        img2={imgHome3}
                        title3='ATMOSFERA PERFETTA'
                        text3="Non c'è niente di meglio che sorseggiare un buon drink accompagnato da giochi di luce e colori."
                        img3={imgHome4}
                    />
                </div>
            }
        </div>
    )
}

export default Aperitivi;