
import '../style/button.css'

function ContactButton() {
    return (
        <a href='#contact'><button className="btnr">
            CONTATTACI
        </button></a>
    )
}

export default ContactButton;