
import imgHome1 from '../images/compleanno/img1.webp'
import imgHome2 from '../images/compleanno/img2.webp'
import imgHome3 from '../images/compleanno/img3.webp'
import imgHome4 from '../images/compleanno/img4.webp'
import 'aos/dist/aos.css'
import '../style/servizi.css'
import Servizi from "../components/servizi";
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react"

function Compleanni() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <div id='bodyColor'>
                    <Servizi
                        headerImg={imgHome1}
                        title='COMPLEANNO'
                        title1='IL COMPLEANNO DEL SECOLO'
                        text1="La tua festa di compleanno sarà il party dell'anno con il nostro DJ al comando della musica. Playlist personalizzabile per ogni esigenza, con la nostra esperienza e professionalità da DJ renderemo la tua festa di compleanno l'evento del secolo"
                        img1={imgHome2}
                        title2='ATMOSFERA UNICA'
                        text2="Effetti luci a tema personalizzabili e audio professionali per creare un'ambiente perfetto per divertirsi e impressionare gli ospiti."
                        img2={imgHome3}
                        title3='PER TUTTI'
                        text3="Servizio perfetto e adatto a ogni età, piccoli e grandi a seconda della vostra esigenza"
                        img3={imgHome4}

                    />
                </div>
            }
        </div>
    )
}

export default Compleanni;