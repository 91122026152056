import * as React from 'react';
import '../style/marketplace.css'
import CubeMarket from './cubeMarket';
import AOS from 'aos';
import { useEffect } from "react"

function CubeMarketSection(props) {
    useEffect(() => {
        AOS.init({
            duration: 3000
        });
    }, []);

    return (
        <div class="content clearfix" data-aos="fade-up">
            <CubeMarket
                id='1'
                title={props.title1}
                text={props.text1}
                img1={props.img1p1}
                img2={props.img2p1}
                img3={props.img3p1}
                product={props.url1}
            />
            <CubeMarket
                id='2'
                title={props.title2}
                text={props.text2}
                img1={props.img1p2}
                img2={props.img2p2}
                img3={props.img3p2}
                product={props.url2}
            />
            <CubeMarket
                id='3'
                title={props.title3}
                text={props.text3}
                img1={props.img1p3}
                img2={props.img2p3}
                img3={props.img3p3}
                product={props.url3}
            />
        </div>
    )
}

export default CubeMarketSection;