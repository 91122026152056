
import Navbar from '../components/navbar';
import imgMatrimonioHome from '../images/imgMatrimonioHome.webp'
import imgApeHome from '../images/aperitivi.webp'
import imgHome1 from '../images/imgHome1.webp'
import pranzicene from '../images/pranzicene.webp'
import comp from '../images/compleanno.webp'
import laurea from '../images/laurea.webp'
import bat from '../images/battesimo.webp'
import tutorial from '../images/tutorialHome.webp'
import Logo from '../images/logo2.png'
import azienda from '../images/festeAziendaliHome.webp'
import Contact from '../components/contact/contact';
import Footer from '../components/footer';
import AOS from 'aos';
import { useEffect } from "react"
import React from 'react';
import { useState } from 'react';
import 'aos/dist/aos.css'
import ContactButton from '../components/buttonContact';
import { ToastContainer, toast } from 'react-toastify';
import Descrizione from '../components/descrizione';
import BounceLoader from "react-spinners/ScaleLoader";
import CardSection from '../components/CardSection';

function Home() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);


    useEffect(() => {
        AOS.init({
            duration: 4000
        });
    }, []);

    const cards = [
        {
            name1: 'MATRIMONIO',
            img1: imgMatrimonioHome,
            location1: '/matrimonio',
            text1: 'La musica giusta può creare una magia che avvolgerà il vostro matrimonio di romanticismo ed emozione',
            name2: 'APERITIVI',
            img2: imgApeHome,
            location2: '/aperitivi',
            text2: 'Per un aperitivo di successo, serve la giusta selezione musicale, scopri la nostra offerta per il tuo evento'
        },
        {
            name1: 'PRANZI E CENE',
            img1: pranzicene,
            location1: '/pranzi&cene',
            text1: 'Dai vita ai tuoi pranzi e alle tuo cene con un DJ professionale, e rendi fantastico il tuo evento',
            name2: 'COMPLEANNI',
            img2: comp,
            location2: '/compleanni',
            text2: 'Festeggia il tuo compleanno con stile, scegli la musica che ami per il tuo grande evento'
        },
        {
            name1: 'BATTESIMI',
            img1: bat,
            location1: '/battesimi',
            text1: 'Un incontro di note e di emozioni per celebrare la felicità e la speranza di un grande evento',
            name2: 'FESTE DI LAUREA',
            img2: laurea,
            location2: '/festedilaurea',
            text2: 'Una festa di laurea indimenticabile? Affida il divertimento a un DJ professionista'
        },
        {
            name1: 'EVENTI AZIENDALI',
            img1: azienda,
            location1: '/battesimi',
            text1: 'La tua azienda merita il meglio, per il tuo evento aziendale affidati al miglior DJ per eventi',
            name2: 'TUTORIALS',
            img2: tutorial,
            location2: '/tutorial',
            text2: 'Imparare a mixare non è solo divertente,ti permette di esprimere te stesso attraverso la musica. Siamo qui per aiutarti a fare proprio questo!'
        }
    ]

    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <div id='bodyColor'>

                    <main>
                        <Navbar />
                        <ToastContainer />


                        <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
                            <div className="row"  >
                                <img alt='maco dj bergamo eventi' id='firstImg' src={imgHome1} style={{ maxHeight: '1200px', right: '3px', boxShadow: '2px 2px 9px black', position: 'absolute', opacity: 0.4, }} className="card-img-top" ></img>
                                <div className="col-lg-1">
                                    <div></div>
                                </div>
                                <div className="col-lg-10" id='marginContact' style={{ justifyContent: 'center', justifyItems: 'center' }}>
                                    <ul>
                                        <li><h3 className="display-5 fw-normal" style={{ color: 'white', marginBottom: '100px' }}>DJ per matrimoni, intrattenimento eventi</h3></li>
                                        <li> <img id='logoHome' src={Logo} alt='logo maco dj eventi' ></img></li>
                                        <li><ContactButton /></li>
                                    </ul>
                                </div>
                                <div className="col-lg-1 " >
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
                            <Descrizione />
                        </div>


                        {cards.map((card, index) => (
                            <div data-aos="fade-up" key={index}>
                                <CardSection 
                                name1={card.name1} img1={card.img1} location1={card.location1} text1={card.text1} 
                                name2={card.name2} img2={card.img2} location2={card.location2} text2={card.text2}
                                />
                            </div>
                        ))}


                        <div id='contact'>
                            <Contact />
                        </div>


                        <Footer />


                    </main>
                </div >
            }
        </div>
    )


}
export default Home;