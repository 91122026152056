import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import * as React from 'react';


function buttonMenu (props) {
    return (
        <Link to={props.location}><Button   size='large' sx={{
            color:'white',
            height:'55px',
            width:'150px',
            '&:hover': {
                boxShadow: '2px 2px 9px black',
                color:'rgb(0,225,72)',
                borderBottom: '2px solid rgb(0, 225, 72)',
                background: 'radial-gradient(circle, rgba(0,0,0,0.54419134396355353) 0%, rgba(0,1,87,0.478132118451025) 100%)',

                


                
            }
        }} >{props.name}</Button></Link>
    )
}

export default buttonMenu;