import * as React from "react";
import AppBar from "@mui/material/AppBar";
import BoxNav from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Logo from "../images/logo.png";
import ButtonMenu from "./buttonMenu";
import ButtonDrawer from "./buttonDrawer";
import NavbarService from "../components/navbarService";
import GoToTop from "../components/goToTop.js";
import { SocialIcon } from "react-social-icons";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Arrow from "../images/left.png";
import tiktok from "../images/tiktok.png";
import LogoNav from "../images/favicon_maco_dj.png";
import Logo3 from "../images/whatsapp_navbar.png";

function Navbar(props) {
  const drawerWidth = 325;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <BoxNav
      onClick={handleDrawerToggle}
      id="bodyColor"
      sx={{
        textAlign: "center",
        alignItems: "center",
        background: "rgb(31,67,124)",
        background:
          "radial-gradient(circle, rgba(31,67,124,0.8941701680672269) 5%, rgba(0,0,0,1) 83%)",
        height: "100%",
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to="/">
          <img
            alt="logo"
            src={Logo}
            className="card-img-top"
            style={{ width: "8rem" }}
          ></img>
        </Link>
      </Typography>
      <Divider />
      <List>
        <ul style={{ listStyleType: "none", marginRight: "15px" }}>
          <li>
            <ButtonDrawer name="HOME" location="/" />
          </li>
          <li>
            <ButtonDrawer name="CHI SIAMO" location="/chisiamo" />
          </li>
          <li>
            <ButtonDrawer name="SIAE" location="/siae" />
          </li>
          <li>
            <ButtonDrawer name="MARKETPLACE" location="/marketplace" />
          </li>
          <li>
            <ButtonDrawer name={<NavbarService />} />
          </li>
          <li>
            <Button
              sx={{
                height: "40px",
                "&:hover": {
                  color: "rgb(0,225,72)",
                  borderBottom: "2px solid rgb(0, 225, 72)",
                },
              }}
            >
              <img src={Arrow}></img>
            </Button>
          </li>
        </ul>
        <section
          className="fixed-bottom"
          style={{ width: "320px", marginBottom: "45px" }}
        >
          <div>
            <SocialIcon
              url="https://www.youtube.com/channel/UCTjUf8Va5hkP1-MDWkHe_0w"
              style={{ margin: "5px", width: "35px", height: "35px" }}
            />
            <SocialIcon
              url="https://open.spotify.com/playlist/0mqMIAJJuLPpX29A7Sy5VH"
              style={{ margin: "5px", width: "35px", height: "35px" }}
            />
            <SocialIcon
              url="https://www.instagram.com/maco_dj/"
              style={{ margin: "5px", width: "35px", height: "35px" }}
            />
            <SocialIcon
              url="https://www.mixcloud.com/macodj/"
              style={{ margin: "5px", width: "35px", height: "35px" }}
            />
            <SocialIcon
              url="https://www.facebook.com/macodjeventi/"
              style={{ margin: "5px", width: "35px", height: "35px" }}
            />
            <Link to="https://www.tiktok.com/@maco_dj_events/">
              <img
                src={tiktok}
                style={{ margin: "5px", width: "35px", height: "35px" }}
                alt="maco dj bergamo eventi"
              ></img>
            </Link>
          </div>
        </section>
      </List>
    </BoxNav>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <BoxNav sx={{ display: "flex" }}>
      <GoToTop />
      <AppBar
        component="nav"
        sx={{ background: "rgba(0,0,0,0.8)", boxShadow: "0,9" }}
      >
        <Toolbar>
          <IconButton
            style={{ color: "rgb(0,225,72)" }}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            edge="start"
            sx={{ mr: 2, display: { sm: "none", flexGrow: 1 } }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            sx={{ display: { sm: "none" } }}
          >
             <Link to="https://wa.me/+393342941917">
                <img
                  src={Logo3}
                  style={{ marginBottom: "5px", width: "25px" }}
                  alt="maco dj bergamo eventi"
                ></img>
              </Link>
          </IconButton>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block", textAlign: "left" },
            }}
          >
            <BoxNav>
              <Link to="/">
                <img
                  src={Logo}
                  className="card-img-top"
                  style={{ width: "11rem" }}
                ></img>
              </Link>
            </BoxNav>
          </Typography>
          <BoxNav sx={{ display: { xs: "none", sm: "block" } }}>
            <ButtonMenu name="HOME" location="/" />
            <ButtonMenu name="CHI SIAMO" location="/chisiamo" />
            <ButtonMenu name="SIAE" location="/siae" />
            <ButtonMenu name="MARKETPLACE" location="/marketplace" />
            <ButtonMenu name={<NavbarService />} />
            <Button
              sx={{
                height: "40px",
                "&:hover": {
                  color: "rgb(0,225,72)",
                  borderBottom: "2px solid rgb(0, 225, 72)",
                },
              }}
            >
              <Link to="https://wa.me/+393342941917">
                <img
                  src={Logo3}
                  style={{ marginBottom: "5px", width: "25px" }}
                  alt="maco dj bergamo eventi"
                ></img>
              </Link>
            </Button>
          </BoxNav>
        </Toolbar>
      </AppBar>
      <BoxNav component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </BoxNav>
    </BoxNav>
  );
}

export default Navbar;
