
import imgHome1 from '../images/battesimo/img1.webp'
import imgHome2 from '../images/battesimo/img2.webp'
import imgHome3 from '../images/battesimo/img3.webp'
import imgHome4 from '../images/battesimo/img4.webp'
import 'aos/dist/aos.css'
import '../style/servizi.css'
import Servizi from "../components/servizi";
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react"

function Battesimi() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
            {loading ?
                (

                    <BounceLoader
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        color=" rgb(0, 225, 72)"
                        cssOverride={{}}
                        loading={loading}
                        height={50}
                        width={5}
                        speedMultiplier={1.5} />

                )
                :
                <div id='bodyColor'>
                    <Servizi
                        headerImg={imgHome1}
                        title='BATTESIMO'
                        title1='UNA GIORNATA SPECIALE'
                        text1="Rendi il tuo battesimo un'esperienza fantastica con il nostro DJ professionale, musica, animazione e tanto altro per il tuo Battesimo "
                        img1={imgHome2}
                        title2='ATMOSFERA MAGICA'
                        text2="Effetti luci e audio professionali per creare un'atmosfera speciale per il tuo Battesimo."
                        img2={imgHome3}
                        title3='DIVERTIMENTO'
                        text3="Rendi il battesimo del tuo bambino ancora più speciale con la musica giusta. Affida il divertimento del tuo evento al nostro dj!"
                        img3={imgHome4}
                    />
                </div>
            }
        </div>
    )
}

export default Battesimi;