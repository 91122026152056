
import imgHome1 from '../images/pranzi&cene/img1.webp'
import imgHome2 from '../images/pranzi&cene/img2.webp'
import imgHome3 from '../images/pranzi&cene/img3.webp'
import imgHome4 from '../images/pranzi&cene/img4.webp'
import 'aos/dist/aos.css'
import '../style/servizi.css'
import Servizi from "../components/servizi";
import React from 'react';
import { useState } from 'react';
import BounceLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react"

function Pranziecene() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);
    return (
        <div>
        {loading ?
            (

                <BounceLoader
                    style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                    color=" rgb(0, 225, 72)"
                    cssOverride={{}}
                    loading={loading}
                    height={50}
                    width={5}
                    speedMultiplier={1.5} />

            )
            :
        <div id='bodyColor'>
           <Servizi
                headerImg={imgHome1}
                title='PRANZI E CENE'
                title1='DIVERTIMENTO'
                text1="Dai vita ai tuoi pranzi e cene con la giusta colonna sonora grazie ai nostri servizi DJ. La musica perfetta per una serata di gastronomia e divertimento per il tuo grande evento"
                img1={imgHome2}
                title2='MUSICA IDEALE'
                text2="Sia che tu voglia ballare o semplicemente rilassarti al tuo evento, la nostra musica renderà la tua cena o pranzo indimenticabile per te e i tuoi invitati"
                img2={imgHome3}
                title3='INDIMENTICABILE'
                text3="Rendiamo la vostra cena o pranzo un evento unico, la nostra musica selezionata unicamente per voi e farà la differenza"
                img3={imgHome4}
            
            />
        </div>
}
</div>
    )
}

export default  Pranziecene;