import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ButtonDrawer from './buttonDrawer';
import Logo from '../images/logo.png'
import Arrow from '../images/left.png'



export default function NavbarService() {
    const [state, setState] = React.useState({
        right: false
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box id='bodyColor'
            sx={{
                width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 280, textAlign: 'center', background: 'rgb(31,67,124)',
                background: ' radial-gradient(circle, rgba(31,67,124,0.8941701680672269) 5%, rgba(0,0,0,1) 83%)', height: '100%'
            }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}

        >
            <List >
                <ListItemButton >
                    <ul style={{ listStyleType: 'none', marginLeft: '20px' }}>
                        <img src={Logo} alt='maco dj bergamo eventi' className="card-img-top" style={{ width: '10rem' }} ></img>
                        <li style={{ marginTop: '20px' }}><ButtonDrawer name='MATRIMONIO' location='/matrimonio' /></li>
                        <li><ButtonDrawer name='APERITIVI' location='/aperitivi' /></li>
                        <li><ButtonDrawer name='PRANZI E CENE' location='/pranzi&cene' /></li>
                        <li><ButtonDrawer name='COMPLEANNI' location='/compleanni' /></li>
                        <li><ButtonDrawer name='BATTESIMI' location='/battesimi' /></li>
                        <li><ButtonDrawer name='FESTE DI LAUREA' location='/festedilaurea' /></li>
                        <li><ButtonDrawer name='FESTE AZIENDALI' location='/eventiaziendali' /></li>
                        <li><ButtonDrawer name='TUTORIALS' location='/tutorial' /></li>
                        <li><Button sx={{
                            height:'40px',
                            marginLeft:'35px',
                            '&:hover': {
                                color: 'rgb(0,225,72)',
                                borderBottom: '2px solid rgb(0, 225, 72)',


                            },

                        }} ><img src={Arrow} alt='maco dj bergamo eventi'></img></Button></li>
                    </ul>
                </ListItemButton>
            </List>
            <Divider />
        </Box >
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)} sx={{
                        color: 'white',
                        height: '55px',
                        width: '150px',
                        '&:hover': {
                            boxShadow: '2px 2px 9px black',
                            color: 'rgb(0,225,72)',
                        }
                    }} >servizi</Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}