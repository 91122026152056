
import imgHome1 from '../images/djfiducia.webp'
import img1 from '../images/professionalità.webp'
import img2 from '../images/stile.webp'
import img3 from '../images/eleganza.webp'
import img4 from '../images/omaggio.webp'
import Logo from '../images/logo2.png'
import '../style/chisiamo.css'
import AOS from 'aos';
import { useEffect } from "react"
import 'aos/dist/aos.css'


function Descrizione() {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    function reveal() {
        var reveals = document.querySelectorAll(".promo");
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }
    window.addEventListener("scroll", reveal);
    reveal();


    function reveal1() {
        var reveals1 = document.querySelectorAll(".textzoom");
        for (var i = 0; i < reveals1.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals1[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
                reveals1[i].classList.add("zoomeffect");
            } else {
                reveals1[i].classList.remove("zoomeffect");
            }
        }
    }
    window.addEventListener("scroll", reveal1);
    reveal1();


    return (

        <div>
            <div className=" row col featurette" style={{ marginLeft: '2px' }}>
                <div className="col-md-2"></div>
                <div className=" col-md-8"  >
                    <div className="testcard ">
                        <div className="row featurette" style={{ marginTop: '5%' }}>
                            <div className="col-md-6" style={{ marginTop: '15%' }} >
                                <h2 className="featurette-heading fw-normal lh-1 textzoom" data-aos="fade-right" data-aos-duration="1000" id="chi-siamo-titles">PER OGNI ESIGENZA</h2>
                                <p className="lead textzoom" id="chi-siamo-text" data-aos="fade-right" data-aos-duration="1000" >REPERTORIO MUSICALE A 360 GRADI PERSONALIZZABILE IN BASE AI GUSTI PERSONALI DEL CLIENTE, DALLA MUSICA ANNI CINQUANTA ALL’HOUSE MODERNA, DAL LATINO AI BALLI DI GRUPPO, DALL’HIP HOP ALL’R&B E DAL ROCK AND ROLL AL BLUES.</p>
                                   
                            </div>
                            <div className="col-md-6 ">
                                <div className="promo overlay" >

                                    <div className="image-wrapper">
                                        <img alt='stile maco dj' src={img2}></img>
                                    </div>
                                    <h2 className="title" data-cta="Unico" style={{ color: 'white' }}>Stile</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testcard " style={{ marginTop: '100px' }}>
                        <div className="row featurette test" >
                            <div className="col-md-6 " style={{ marginTop: '15%' }} >
                                <h2 className="featurette-heading fw-normal lh-1 textzoom" data-aos="fade-right" data-aos-duration="1000" id="chi-siamo-titles">IL CLIENTE PRIMA DI TUTTO</h2>
                                <p className="lead textzoom" data-aos="fade-right" data-aos-duration="1000" id="chi-siamo-text">DJ PROFESSIONISTA PER EVENTI CON ESPERIENZA DECENNALE NEL SETTORE DEGLI EVENTI PER MATRIMONI, COMPLEANNI, FESTE, LAUREE, ANNIVERSARI, BATTESIMO, EVENTI PRIVATI E PARTY AZIENDALI.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <div className="promo overlay" >
                                    <div className="image-wrapper">
                                        <img alt='professionalità maco dj' src={img1} className='reveal' ></img>
                                    </div>
                                    <h2 className="title" data-cta="Decennale" style={{ color: 'white' }}>Professionalità</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testcard " style={{ marginTop: '100px', marginBottom: '100px' }}>
                        <div className="row featurette test" >
                            <div className="col-md-6 " style={{ marginTop: '15%' }} >
                                <h2 className="featurette-heading fw-normal lh-1 textzoom" data-aos="fade-right" data-aos-duration="1000" id="chi-siamo-titles" >DA SOGNO</h2>
                                <p className="lead textzoom" id="chi-siamo-text" data-aos="fade-right" data-aos-duration="1000" >ATMOSFERA UNICA PER IL VOSTRO EVENTO GRAZIE AD UN IMPIANTO AUDIO E LUCI DI ULTIMA GENERAZIONE.  OFFRIAMO TUTTI I SERVIZI DI CUI HAI BISOGNO, TRA CUI FOTOGRAFO, AUTISTA PER MATRIMONI, ANIMATORE, VOCALIST, ADDOBBI PER LE FESTE, SISTEMA DI ILLUMINAZIONE E TANTO ALTRO ANCORA PER IL TUO EVENTO.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <div className="promo overlay" >
                                    <div className="image-wrapper">
                                        <img alt='atmosfera maco dj' src={img3}></img>
                                    </div>
                                    <h2 className="title" data-cta="Magica" style={{ color: 'white' }}>Atmosfera</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testcard " style={{ marginTop: '100px', marginBottom: '100px' }}>
                        <div className="row featurette test" >
                            <div className="col-md-6 " style={{ marginTop: '15%' }} >
                                <h2 className="featurette-heading fw-normal lh-1 textzoom" data-aos="fade-right" data-aos-duration="1000" id="chi-siamo-titles" >OMAGGIO</h2>
                                <p className="lead textzoom" id="chi-siamo-text" data-aos="fade-right" data-aos-duration="1000" >PER I NOSTRI FANTASTICI CLIENTI OFFRIAMO FANTASTICI REGALI IN OMAGGIO AD OGNI NOSTRO EVENTO.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <div className="promo overlay" >
                                    <div className="image-wrapper">
                                        <img alt='prodotti maco dj' src={img4}></img>
                                    </div>
                                    <h2 className="title" data-cta="Speciale" style={{ color: 'white' }}>Regalo</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
            <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
                <div className="row"  >
                    <img id='firstImg' alt='i nostri servizi maco dj' src={imgHome1} style={{ maxHeight: '1400px', maxWidth: '100%', right: '3px', position: 'absolute', opacity: '0.8' }} className="card-img-top" ></img>
                    <div className="col-lg-1">
                        <div></div>
                    </div>
                    <div className="col-lg-10" id='marginContact' style={{}}>
                        <ul>
                            <li><img alt='maco dj eventi' src={Logo} style={{ width: '600px' }} ></img></li>
                            <li><h3 className="display-5 fw-normal" style={{ color: 'white' }}>I NOSTRI SERVIZI</h3></li>
                        </ul>
                    </div>
                    <div className="col-lg-1 " >
                        <div></div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Descrizione;